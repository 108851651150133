import { MatSidenav } from '@angular/material/sidenav';
import { Component, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { SecureLayoutStore } from '../secure-layout-store/secure-layout.store';


@Component({
  selector: 'app-secure-layout',
  templateUrl: './secure-layout.component.html',
  styleUrls: ['./secure-layout.component.scss'],
  providers: [SecureLayoutStore]
})
export class SecureLayoutComponent {
  private unsubscribe$ = new Subject<void>();
  @ViewChild('sidenav3') sidenav!: MatSidenav;

  isSideNavOpen$ = this.layoutStore.isSideNavOpen$;

  constructor(
    private router: Router,
    public layoutStore: SecureLayoutStore) {

    // routing events 
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event: any) => {
        this._navigationInterceptor(event);
    });

  }

  private _navigationInterceptor(event: Event): void {
    // close sidenav on routing (switching page
      if (event instanceof NavigationStart) {
          this.sidenav.close();
      }    
  }

  onToggleMe() {
    // this.sidenav.toggle();
    console.log("onToggleMe(): clicked")
  }

}
