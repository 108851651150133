import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";

@Injectable()
export class CustomInterceptor implements HttpInterceptor {

	constructor() {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>  {
		/* withCredentials is necessary so that the server can set the cookie in the browser, when web server address is different from api server address */
		req = req.clone({ withCredentials: true });
		return next.handle(req);
	}
}