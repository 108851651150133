import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppMaterialModule } from './app-material.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SecureLayoutComponent } from './layouts/secure/secure-layout/secure-layout.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { appReducer } from './app.reducer';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './shared/auth/auth.effects';
import { RootLayoutComponent } from './layouts/root-layout/root-layout.component';
import { SecureSidenavComponent } from './layouts/secure/secure-sidenav/secure-sidenav.component';
import { SecureHeaderComponent } from './layouts/secure/secure-header/secure-header.component';
import { SecureFooterComponent } from './layouts/secure/secure-footer/secure-footer.component';
import { LoadingIndicatorComponent } from './shared/loading-indicator/loading-indicator.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BidaSvgComponent } from "./shared/components/bida-svg/bida-svg.component";
import { CustomInterceptor } from './shared/api/custom.interceptor';
import { PublicLayoutComponent } from './layouts/public/public-layout/public-layout.component';
import { PublicHeaderComponent } from './layouts/public/public-header/public-header.component';
import { PublicFooterComponent } from './layouts/public/public-footer/public-footer.component';
import { appInitProvider } from './app-init';

@NgModule({
  declarations: [
    AppComponent,
    RootLayoutComponent,
    SecureLayoutComponent,
    PublicLayoutComponent,
    PublicHeaderComponent,
    PublicFooterComponent,
    SecureSidenavComponent,
    SecureHeaderComponent,
    SecureFooterComponent,
  ],
  imports: [
    AppMaterialModule,
    BrowserModule,
    // ReactiveFormsModule,
    ScrollingModule,
    HttpClientModule,
    AppRoutingModule,
    NoopAnimationsModule,
    StoreModule.forRoot(appReducer),
    EffectsModule.forRoot([AuthEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    LoadingIndicatorComponent,
    BidaSvgComponent
],
  providers: [
    // ActivityApiService,
    // DatePipe,
    appInitProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true
    }
    // provideStore({
    //   auth: authReducer,
    //   // auth: fromApp.authReducer,
    // }),
    // provideEffects([AuthEffects]),
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ApiInterceptor,
    //   multi: true
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
