import { createReducer, on } from "@ngrx/store";
import { ApiActions } from "./api.actions";

export interface ApiState {
	accessToken: string;
	tokenExp: string;
	refreshToken: string;
}

const initialApiState: ApiState = {
	accessToken: "",
	tokenExp: "",
	refreshToken: ""
};

export const apiReducer = createReducer(
	initialApiState,
	on(ApiActions.setAccessToken, (state, action) => {
		return {
			...state,
			accessToken: action.accessToken,
			tokenExp: action.tokenExp,
			refreshToken: action.refreshToken
		};
	}),
	on(ApiActions.clearAccessToken, (state, action) => {
		return {
			...state,
			accessToken: "",
			tokenExp: "",
			refreshToken: ""
		};
	})
);


