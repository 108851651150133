import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class SecureContentScrollService {
	// tslint:disable-next-line:variable-name
	private scrollToTopSubject = new Subject<void>();
	scrollToTop$ = this.scrollToTopSubject.asObservable();

	triggerScrollToTop() {
		this.scrollToTopSubject.next();
	}
}