import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/auth/auth.guard';
import { RootLayoutComponent } from './layouts/root-layout/root-layout.component';
import { PublicLayoutComponent } from './layouts/public/public-layout/public-layout.component';

const routes: Routes = [
  {
    path: '',
    component: RootLayoutComponent,
    children: [
      { path: 'dashboard',
        loadChildren: () => import ('./dashboard/dashboard.module').then(m => m.DashhboardModule),
        canActivate: [AuthGuard] },
      { path: 'passes',
        loadChildren: () => import ('./pass/pass.module').then(m => m.PassModule),
        canActivate: [AuthGuard] },
      { path: 'inbox',
        loadChildren: () => import ('./inbox/inbox.module').then(m => m.InboxModule),
        canActivate: [AuthGuard] },
      { path: 'locators',
        loadChildren: () => import ('./locator/locator.module').then(m => m.LocatorModule),
        canActivate: [AuthGuard] },
      { path: 'vehicles',
        loadChildren: () => import ('./vehicle/vehicle.module').then(m => m.VehicleModule),
        canActivate: [AuthGuard] },
      { path: 'users',
        loadChildren: () => import ('./user/user.module').then(m => m.UserModule),
        canActivate: [AuthGuard] },
      { path: 'roles',
        loadChildren: () => import('./role/role.module').then(m => m.RoleModule),
        canActivate: [AuthGuard] },
      { path: 'reports/activity', 
        loadChildren: () => import('./reports/activity/activity-report.module').then(m => m.ActivityReportModule),
        canActivate: [AuthGuard] },      
      { path: '',
        loadChildren: () => import ('./home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard] },
      ]
  },
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      {
        path: 'privacy',
        loadChildren: () => import ('./privacy/privacy.module').then(m => m.PrivacyModule),
      },
      {
        path: 'terms',
        loadChildren: () => import ('./terms/terms.module').then(m => m.TermsModule),
      }
    ]
  },
  { path: 'login', 
    loadChildren: () => import ('./login/login.module').then(m => m.LoginModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
