import { APP_INITIALIZER, FactoryProvider } from "@angular/core";
import { AuthService } from "./shared/auth/auth.service";
import { Store } from "@ngrx/store";
import { AppState } from "./app.reducer";
import { AuthApiLoginResponse, AuthApiPermissionCodeListResponse } from "./shared/auth/auth-api.types";
import { AuthUser } from "./shared/auth/models/auth-user.model";
import { AuthSite } from "./shared/auth/models/auth-logspace-locator.model";
import { AuthActions } from "./shared/auth/auth.actions";


export function initAppFactory(authService: AuthService, store: Store<AppState>): () => Promise<any> {
	// load the current user specified in the server cookie from the server
	// if there is a current user, show home page
	// if there is not current user, show login page
	// return a promise
	return () => {
		return new Promise<any>((resolve, reject) => {
			authService.loadCurrentUser()
				.then((resp: AuthApiLoginResponse | null) => {
					if (!resp) {
						store.dispatch(AuthActions.noCurrentUser());
						resolve(true);
						return;
					}
					const user: AuthUser = {
						userId: resp.data.userId,
						firstName: resp.data.firstName,
						lastName: resp.data.lastName
					};

					const site: AuthSite = {
						logspaceId: resp.data.dLogspaceId,
						logspaceName: resp.data.dLogspaceName,
						locatorId: resp.data.dLocatorId,
						locatorName: resp.data.dLocatorName
					}
					
					authService.getPermissions(site.logspaceId ?? "", user.userId ?? "")
						.then((resp: AuthApiPermissionCodeListResponse) => {
							store.dispatch(AuthActions.loadCurrentUserSuccess({user, site, permissions: resp.data}));
							resolve(true);
						})
						.catch((err) => {
							store.dispatch(AuthActions.loadCurrentUserFailure({error: err}));
							resolve(true);
						});
				})
				.catch((err) => {
					store.dispatch(AuthActions.loadCurrentUserFailure({error: err}));
					resolve(true);
				});
		});
	}
}

export const appInitProvider: FactoryProvider = {
	provide: APP_INITIALIZER,
	useFactory: initAppFactory,
	deps: [AuthService, Store], // Inject dependencies if needed
	multi: true // Allow multiple app initializers
}