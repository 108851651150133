import { Actions, createEffect, ofType } from "@ngrx/effects";
// import { AuthService } from "./auth.service";
import { AuthActions } from "./auth.actions";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { AuthService } from "./auth.service";
import { AuthApiLoginResponse } from "./auth-api.types";
import { HttpClient } from "@angular/common/http";
import { catchError, concatMap, map, switchMap, take, tap } from "rxjs/operators";
import { EMPTY, from, of } from "rxjs";
import { AuthUser } from "./models/auth-user.model";
import { AuthSite } from "./models/auth-logspace-locator.model";
import { Router } from "@angular/router";
// import { tap } from "rxjs";

@Injectable()
export class AuthEffects {
	constructor(
		private actions$: Actions,
		private auth: AuthService,
		private http: HttpClient,
		private router: Router,
		@Inject(PLATFORM_ID) private platformId: Object
	) {}
		// actions$.subscribe(action => {

		// 	if (action.type === AuthActions.getCurrentUser.type) {
		// 		console.log("getCurrentUserEffect");
		// 		// if (isPlatformBrowser(this.platformId)) {
		// 			auth.getCurrentUser().pipe(take(1), catchError(error => EMPTY)).subscribe({
		// 				next: (user: AuthApiLoginResponse | undefined)=> {
		// 					console.log("getCurrentUserEffect:", user);
		// 				},
		// 				error: (err: any) => { /* do nothing */ }
		// 			});
		// 		// }

		// 	} else if (action.type === AuthActions.login.type) {
		// 		const myAction = action as ReturnType<typeof AuthActions.login>;
		// 		console.log("loginEffect:", myAction.user, myAction.site);
		// 	// } else if (action.type === AuthActions.testAction.type) {
		// 	// 	console.log("testActionEffect:");
		// 	// 	if (isPlatformBrowser(this.platformId)) {
		// 	// 		console.log('Running on the browser');
		// 	// 		http.get<AuthApiLoginResponse | ApiErrorResponse>("/api/v1/wassup/").pipe(take(1)).subscribe({
		// 	// 			next: (data: any) => {
		// 	// 				console.log("testActionEffect:2:", data);
		// 	// 			},
		// 	// 			error: (err: any) => {
		// 	// 				console.log("testActionEffect:3:", err);
		// 	// 			}
		// 	// 		});
		// 	// 	} else if (isPlatformServer(this.platformId)) {
		// 	// 		http.get<AuthApiLoginResponse | ApiErrorResponse>("http://labida.lcl:8080/api/v1/wassup/").pipe(take(1)).subscribe({
		// 	// 			next: (data: any) => {
		// 	// 				console.log("testActionEffect:2:", data);
		// 	// 			},
		// 	// 			error: (err: any) => {
		// 	// 				console.log("testActionEffect:3:", err);
		// 	// 			}
		// 	// 		});
		// 	// 	}
		// 	}
		// });

	// getCurrentUserEffect$ = createEffect(() => {
	// 		return this.actions$.pipe(
	// 			ofType(AuthActions.getCurrentUser),
	// 			tap(() => {
	// 				console.log("getCurrentUserEffect");
	// 			})

	// 		)
	// 	},
	// 	{ functional: true}
	// );
	// }

	// getCurrentUserEffect$ = createEffect(() => {
	// 	return this.actions$.pipe(
	// 		ofType(AuthActions.getCurrentUser),
	// 		switchMap(() => this.auth.getCurrentUser()
	// 			.pipe(
	// 				tap(resp => { console.log("getCurrentUser resp:", resp); }),
	// 				switchMap((resp: AuthApiLoginResponse | undefined) => {
	// 					if (!resp) {
	// 						return of(AuthActions.getCurrentUserFailure({error: "No user found"}));
	// 					}
	// 					const user = {userId: resp.data.userId, firstName: resp.data.firstName, lastName: resp.data.lastName} as AuthUser;
	// 					const site = {
	// 						logspaceId: resp.data.dLogspaceId,
	// 						logspaceName: resp.data.dLogspaceId,
	// 						locatorId: resp.data.dLocatorId,
	// 						locatorName: resp.data.dLocatorName
	// 					} as AuthSite;
	// 					return this.auth.getPermissions(site.logspaceId ?? "", user.userId ?? "").pipe(
	// 						tap(permissions => { console.log("getCurrentUser:", permissions.data); }),
	// 						map(({data}) => AuthActions.getCurrentUserSuccess({user, site, permissions: data})),
	// 						catchError((error) => of(AuthActions.getCurrentUserFailure({error})))
	// 					);
	// 				}),
	// 				catchError(error => of(AuthActions.getCurrentUserFailure({error})))
	// 			)
	// 		)
	// 	);
	// });

	// getCurrentUserSuccessEffect$ = createEffect(() => {
	// 	return this.actions$.pipe(
	// 		ofType(AuthActions.getCurrentUserSuccess),
	// 		tap(() => {
	// 			this.router.navigate(["/"]);
	// 		})			
	// 	);
	// }, { dispatch: false });

	loginEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.login),
			switchMap(({bidaId , password}) => this.auth.login(bidaId, password)
				.pipe(
					tap(resp => {						
						console.log("login resp:", resp);
					}),
					concatMap((resp: AuthApiLoginResponse) => {
						const user = {userId: resp.data.userId, firstName: resp.data.firstName, lastName: resp.data.lastName} as AuthUser;
						const site = {
							logspaceId: resp.data.dLogspaceId,
							logspaceName: resp.data.dLogspaceId,
							locatorId: resp.data.dLocatorId,
							locatorName: resp.data.dLocatorName
						} as AuthSite;
						return from(this.auth.getPermissions(site.logspaceId ?? "", user.userId ?? "")).pipe(
							// tap(permissions => { console.log("permissions resp:", permissions.data); }),
							map(({data}) => AuthActions.loginSuccess({user, site, permissions: data})),
							catchError((error) => {
								return of(AuthActions.loginFailure({error}));
							})
						);
					}),
					catchError((error) => {
						return of(AuthActions.loginFailure({error}));
					})
				)
			)
		);
	});

	loginSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.loginSuccess),
			tap(() => {
				this.router.navigate(["/"]);
			})			
		);
	}, { dispatch: false });


	logoutEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.logout),
			switchMap(() => this.auth.logout()
				.pipe(
					tap(resp => { console.log("logout resp:", resp); }),
					map(() => AuthActions.logoutSuccess()),
					catchError((error) => of(AuthActions.logoutFailure({error})))
				)
			)
		);
	});

	logoutSuccessEffect$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AuthActions.logoutSuccess),
			tap(() => {
				this.router.navigate(["/login"]);
			})			
		);
	}, { dispatch: false });

}

function Effect(): (target: AuthEffects, propertyKey: "loginEffect$") => void {
	throw new Error("Function not implemented.");
}
