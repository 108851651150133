<app-secure-header class="bdc-appbar" (sidenavToggle)="sidenav3.toggle()" (click)="onHeaderClick($event)"></app-secure-header>
<mat-sidenav-container >
	<mat-sidenav class="bdc-sidenav" #sidenav3 mode="over" [opened]="isSideNavOpen$ | async" [autoFocus]="false"
	[fixedInViewport]="true" [fixedTopGap]="0"
                 [fixedBottomGap]="0">
		<app-secure-sidenav (sidenavToggle)="sidenav3.toggle()"></app-secure-sidenav>
	</mat-sidenav>
	<mat-sidenav-content class="bdc-sidenav-content">
			<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
<loading-indicator></loading-indicator>