import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { selectCurrentUser, selectIsLoggedIn } from 'src/app/shared/auth/auth.selectors';
import { AuthService } from 'src/app/shared/auth/auth.service';

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss'],
})
export class PublicHeaderComponent {

  selectIsLoggedIn$ = this.store.select(selectIsLoggedIn)
  currentUser$ = this.store.select(selectCurrentUser);

  constructor(private store: Store<AppState>,
    private auth: AuthService) { }

  logout() {
    this.auth.logout().pipe(take(1)).subscribe({
      next: (data: any) => {
        console.log("logout:", data);
      },
      error: (err: any) => {
        console.log("logout:", err);
      }
    })
  }
}
