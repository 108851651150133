import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";

export interface SecureLayoutState {
	sideNavOpen: boolean;
}

@Injectable()
export class SecureLayoutStore extends ComponentStore<SecureLayoutState> {
	sideNavOpen$ = this.select(state => state.sideNavOpen);

	constructor() {
		super({ sideNavOpen: false });
	}

	readonly toggleSideNav = this.updater((state) => ({ ...this.state, sideNavOpen: !state.sideNavOpen}));

	readonly isSideNavOpen$ = this.select((state) => state.sideNavOpen);

}