import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { AuthActions } from 'src/app/shared/auth/auth.actions';
import { selectCurrentSite, selectCurrentUser, selectIsLoggedIn } from 'src/app/shared/auth/auth.selectors';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { AuthSite } from 'src/app/shared/auth/models/auth-logspace-locator.model';
import { AuthUser } from 'src/app/shared/auth/models/auth-user.model';
import { SitePickerDataItem, SitepickerDialogComponent } from 'src/app/sitepicker-dialog/sitepicker-dialog.component';

@Component({
  selector: 'app-secure-header',
  templateUrl: './secure-header.component.html',
  styleUrls: ['./secure-header.component.scss']
})
export class SecureHeaderComponent {
  
  @Output() sidenavToggle = new EventEmitter<void>();
  selectIsLoggedIn$ = this.store.select(selectIsLoggedIn)
  currentUser$ = this.store.select(selectCurrentUser);
  currentSiteName$ = this.store.select(selectCurrentSite).pipe(
    map((site) => {
      const logspaceName = site ? site.logspaceName : "";
      const locatorName = site ? site.locatorName : "";
      return logspaceName && locatorName ? `${logspaceName}:${locatorName}` : logspaceName || locatorName || '';
    }
  ));
  currentSite$ = this.store.select(selectCurrentSite);

  constructor(private store: Store<AppState>,
    private auth: AuthService,
    public dialog: MatDialog) { }

  onToggleSidenav() {
    this.sidenavToggle.emit();
  }  

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  onSitePicker() {
    this.currentSite$.pipe(take(1)).subscribe((site: AuthSite | null) => {
      if (!!site) {
        const dialogRef = this.dialog.open(SitepickerDialogComponent, {
          data: {
            currentSiteId: site ? site.logspaceId : null,
          }
        });
        dialogRef.afterClosed().subscribe((selecedSite: SitePickerDataItem | null)  => {
          this.currentUser$.pipe(take(1)).subscribe((user: AuthUser | null) => {
            if (!!user && !!selecedSite) {
              this.store.dispatch(AuthActions.switchSite({
                userId: user.userId ?? "",
                site: {
                  logspaceId: selecedSite.siteId,
                  logspaceName: selecedSite.siteName,
                  locatorId: selecedSite.locatorId,
                  locatorName: selecedSite.locatorName
                }
              }));
            }
          });
        });
      }
    });
  }
  
}
