import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './app.reducer';
import { NavigationService } from './shared/services/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  title = 'bdpweb';  

  constructor(private store: Store<AppState>,
    private navigation: NavigationService) {
      this.navigation.startToSaveHistory();
  }

}
