import { Component } from '@angular/core';
import { selectIsLoggedIn } from '../../shared/auth/auth.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';

@Component({
  selector: 'app-root-layout',
  templateUrl: './root-layout.component.html',
  styleUrls: ['./root-layout.component.scss']
})
export class RootLayoutComponent {
  selectIsLoggedIn$ = this.store.select(selectIsLoggedIn)

  constructor(private store: Store<AppState>) {    
  }
}
