<app-secure-header (sidenavToggle)="sidenav3.toggle()"></app-secure-header>
<mat-sidenav-container autosize="true">
	<mat-sidenav #sidenav3 mode="over" [opened]="isSideNavOpen$ | async" [autoFocus]="false"
	[fixedInViewport]="true" [fixedTopGap]="0"
                 [fixedBottomGap]="0">
		<app-secure-sidenav></app-secure-sidenav>		
	</mat-sidenav>
	<mat-sidenav-content>
			<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
<loading-indicator></loading-indicator>