import { createAction, props } from "@ngrx/store";
import { AuthSite } from "./models/auth-logspace-locator.model";
import { AuthUser } from "./models/auth-user.model";
import { AuthPermissionList } from "./models/auth-permissions.model";

export const login = createAction(
	"[Auth] Login",
	props<{ bidaId: string, password: string }>()
);

export const loginSuccess = createAction(
	"[Auth] Login Success",
	props<{ user: AuthUser, site: AuthSite, permissions: AuthPermissionList}>()
);

export const loginFailure = createAction(
	"[Auth] Login Failure",
	props<{ error: any }>()
);

export const logout = createAction(
	"[Auth] Logout"
);

export const logoutSuccess = createAction(
	"[Auth] Logout Success"
);

export const logoutFailure = createAction(
	"[Auth] Logout Failure",
	props<{ error: any }>()
);

export const loadCurrentUser = createAction(
	"[Auth] Load Current User"
);

export const loadCurrentUserSuccess = createAction(
	"[Auth] Load Current User Success",
	props<{ user: AuthUser, site: AuthSite, permissions: AuthPermissionList}>()
);

export const loadCurrentUserFailure = createAction(
	"[Auth] Load Current User Failure",
	props<{ error: any }>()
);

export const noCurrentUser = createAction(
	"[Auth] No Current User"
);

export const testAction = createAction(
	"[Auth] Test Action"
);

export const AuthActions = {
	login,
	loginSuccess,
	loginFailure,
	logout,
	logoutSuccess,
	logoutFailure,
	loadCurrentUser,
	loadCurrentUserSuccess,
	loadCurrentUserFailure,
	noCurrentUser,
	testAction,
};
