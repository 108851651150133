import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiBaseListResponse, ApiBaseResponse } from '../shared/api/api.types';
import { ApiFilterTerm } from '../shared/api/api.list';

export interface ApiSite {
	siteId: string;
	siteName: string;
	siteType: string;
	siteAddress: string;
	parentSiteId: string;
	parentSiteName: string;
}

export interface ApiSiteLocatorListResponseItem {
	siteId: string;
	siteName: string;
	siteAddress: string;
	status: string;
	locatorId: string;
	locatorName: string;
	locadtorAddress: string;
	personnelId: string;
	userRoleId: string;
	userRoleName: string;
}

export interface ApiSiteLocatorListResponseData {
	dSiteId: string;
	dlocatorId: string;
	dSiteName: string;
	dLocatorName: string;
	logspaceList: ApiSiteLocatorListResponseItem[]
}

export interface ApiSiteLocatorListResponse extends ApiBaseResponse{
	data: ApiSiteLocatorListResponseData;
  paging: {
    currPageNum: string,
    itemPageCount: string,
    pageTotalCount: string,
    itemTotalCount: string
  }
}

@Injectable()
export class SitePickerApiService {

	baseUrl: string = environment.apiBaseUrl
	
	constructor(private http: HttpClient) { }

	getSiteList(
		filterTerms: ApiFilterTerm[],
		pageIndex1: number,
		pageSize: number,
		scope: number): Observable<ApiBaseListResponse<ApiSite>> {
		let params = new HttpParams()
		.append('limit', pageSize.toString())
		.append('page', pageIndex1.toString())
		.append('scope', scope.toString());

		if (filterTerms && filterTerms.length > 0) {
			for (var term of filterTerms) {
				params = params.append('query', term.value);
			}
		}

		return this.http.get<ApiBaseListResponse<ApiSite>>(
			`${this.baseUrl}/site/list/`,
			{ params: params });
	}

	getSiteLocatorList(
		userId: string,
		filterTerms: ApiFilterTerm[],
		pageIndex1: number,
		pageSize: number,
		scope: number): Observable<ApiSiteLocatorListResponse> {
		let params = new HttpParams()
		.append('limit', pageSize.toString())
		.append('page', pageIndex1.toString())
		.append('scope', scope.toString());

		if (filterTerms && filterTerms.length > 0) {
			for (var term of filterTerms) {
				params = params.append('query', term.value);
			}
		}

		return this.http.get<ApiSiteLocatorListResponse>(
			`${this.baseUrl}/${userId}/site/list/`,
			{ params: params });
	}
}

