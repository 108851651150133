import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';

@NgModule({
	imports :[MatCardModule, MatFormFieldModule, MatInputModule, MatIconModule, MatButtonModule,
	MatToolbarModule, MatSidenavModule, MatDividerModule, MatListModule],
	exports :[MatCardModule, MatFormFieldModule, MatInputModule, MatIconModule, MatButtonModule,
	MatToolbarModule, MatSidenavModule, MatDividerModule, MatListModule]
})
export class AppMaterialModule {}