
import { ActionReducerMap } from '@ngrx/store';
import * as fromApi from './shared/api/api.reducer';
import * as fromAuth from './shared/auth/auth.reducer';

export interface AppState {
	api: fromApi.ApiState;
	auth: fromAuth.AuthState;
}

export const appReducer: ActionReducerMap<AppState> = {
	api: fromApi.apiReducer,
	auth: fromAuth.authReducer,
};