<div>
	<button mat-icon-button (click)="onToggleSidenav()">
		<mat-icon>close</mat-icon>
	</button>	
</div>
<mat-nav-list>
	<a mat-list-item routerLink="/dashboard">Dashboard</a>
	<a mat-list-item routerLink="/passes">Passes</a>
	<a mat-list-item routerLink="/inbox">Inbox</a>
	<mat-divider></mat-divider>
	<a mat-list-item routerLink="/locators">Locators</a>
	<a mat-list-item routerLink="/vehicles">Vehicles</a>
	<mat-divider></mat-divider>
	<mat-divider></mat-divider>
	<h3 matSubheader>Reports</h3>
	<a mat-list-item routerLink="/reports/activity">Activity</a>
	<mat-divider></mat-divider>
	<h3 matSubheader>Security</h3>
	<a mat-list-item routerLink="/users">Users</a>
	<a mat-list-item routerLink="/roles">Roles</a>
	<a mat-list-item routerLink="/test">Test</a>
</mat-nav-list>