import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { EMPTY, lastValueFrom, Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { ApiBaseResponse } from "../api/api.types";
import { AuthApiLoginResponse, AuthApiPermissionCodeListResponse } from "./auth-api.types";
import { AppState } from "../../app.reducer";
import { Store } from "@ngrx/store";
import { ApiActions } from "../api/api.actions";
import { AuthActions } from "./auth.actions";
import { Router } from "@angular/router";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { environment } from "../../../environments/environment";

export interface AuthToken {
	accessToken: string;
	tokenExp: number;
	refreshToken: string;
}

const fHttpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json'
	}),
	observe: 'response' as const,
	withCredentials: true
}

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	baseUrl: string = environment.apiBaseUrl

	constructor(
		private httpClient: HttpClient,
		private store: Store<AppState>,
		private router: Router,
		@Inject(PLATFORM_ID) private platformId: Object
	){}
	// private isAuthenticated: boolean = false;

	public login(userHandle: string, password: string): Observable<AuthApiLoginResponse> {
		return this.httpClient
			.post<AuthApiLoginResponse>(
				`${this.baseUrl}/login/`,
				{ userHandle: userHandle, password: password },
				fHttpOptions,
			)
			.pipe(
				tap(resp => {
					resp = resp as HttpResponse<AuthApiLoginResponse>;
					console.log("SignIn: ")					
					if (resp.status === 200) {
						const loginResponse = resp.body as AuthApiLoginResponse;
						// console.log(loginResponse);
						// this.store.dispatch(
						// 	ApiActions.setAccessToken(
						// 		{ accessToken: loginResponse.accessToken,
						// 			tokenExp: loginResponse.tokenExp,
						// 			refreshToken: loginResponse.refreshToken }
						// 	)
						// );
						const site = {
							logspaceId: loginResponse.data.dLogspaceId,
							logspaceName: loginResponse.data.dLogspaceName,
							locatorId: loginResponse.data.dLocatorId,
							locatorName: loginResponse.data.dLocatorName
						}
						const user = {
							userId: loginResponse.data.userId,
							firstName: loginResponse.data.firstName,
							lastName: loginResponse.data.lastName
						}
						// this.store.dispatch(
						// 	AuthActions.login({user, site})
						// )
						this.router.navigateByUrl("");
					}
				}),
				map(resp => { 
					return resp.body as AuthApiLoginResponse;
				})
			);
	}	

	// login2(username: string, password: string): boolean {
	// 	// Check if the username and password are valid
	// 	if (username === 'admin' && password === 'password') {
	// 		this.isAuthenticated = true;
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// }

	public logout(): Observable<ApiBaseResponse> {
		return this.httpClient
			.post<ApiBaseResponse>(
				`${this.baseUrl}/logout/`,
				null,
				fHttpOptions)
			.pipe(
				tap(resp => {
					const temp = resp as HttpResponse<ApiBaseResponse>;
					// if (temp.status === 200) {
					// 	this.store.dispatch(AuthActions.logout());
					// }
					console.log("AuthService.logout():resp:", resp);
					return resp.body as ApiBaseResponse;
				}),
				map(resp => {
					return resp.body as ApiBaseResponse;
				})
			);
	}

	// selectIsLoggedIn(): boolean {
	// 	// return this.isAuthenticated;
	// }

	public getCurrentUser(): Observable<AuthApiLoginResponse | undefined> {		
		var url: string = `${this.baseUrl}/user/`
		return this.httpClient
			.get<AuthApiLoginResponse | undefined>(
					url, fHttpOptions)
				.pipe(
					catchError(error => {
						console.log("caught error");
						return EMPTY
					}),
					map(resp => {
						return resp.body as AuthApiLoginResponse | undefined;
					})
				);
	}

	// return a Promise that returns the current user
	public async loadCurrentUser(): Promise<AuthApiLoginResponse | null> {
		var url: string = `${this.baseUrl}/user/`
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			observe: 'response' as const,
			withCredentials: true
		};

		try {
			const response = await lastValueFrom(
				this.httpClient.get<AuthApiLoginResponse>(url, httpOptions).pipe(
					map(resp => {
						if (resp.status === 200) {
							return resp.body as AuthApiLoginResponse;
						} else {
							console.error('API request failed with status:', resp.status);
							return null; // Or a deafult value
						}
					}),
					catchError(error => {
						console.error('API request failed with status:', error);
						return of(null);
					})
				)
			);
			return response;
		} catch (error) {
			console.error('An unexpected error occured:', error);
			return null; 
		}
	}

	// public getPermissions(siteId: string, userId: string) : Observable<AuthApiPermissionCodeListResponse> {
	// 	let params = new HttpParams()
	// 		.append('logspace', siteId)
	// 		.append('user', userId);
	// 	return this.httpClient.get<AuthApiPermissionCodeListResponse>(
	// 		`${this.baseUrl}/permissioncode/list/`, { ...fHttpOptions, observe: 'body' as const, params });
	// }

	public async getPermissions(siteId: string, userId: string): Promise<AuthApiPermissionCodeListResponse> {

		let params = new HttpParams()
			.append('logspace', siteId)
			.append('user', userId);

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			observe: 'response' as const,
			withCredentials: true,
			params: params
		};
		
		var url: string = `${this.baseUrl}/permissioncode/list/`
		try {
			const response = await lastValueFrom(
				this.httpClient.get<AuthApiPermissionCodeListResponse>(url, httpOptions).pipe(
					map(resp => resp.body as AuthApiPermissionCodeListResponse),
					catchError(error => {
						console.log("caught error");
						throw error;
					})
				)
			);
			return response;
		} catch (error) {
			console.log('An error occured:', error);
			throw error;
		}
	}

	public setExampleCookie() {
		this.httpClient.get(`${this.baseUrl}/testSetCookie/`).subscribe();
	}

	public getExampleCookie() {
		this.httpClient.get(`${this.baseUrl}/testGetCookie/`).subscribe();
	}



	// public getSite(logspaceId: string, logcatorId: string): Observable<int> {
		
	// }
}