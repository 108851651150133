import { createReducer, on } from "@ngrx/store";
import { AuthUser } from "./models/auth-user.model";
import { AuthSite } from "./models/auth-logspace-locator.model";
import { AuthActions } from "./auth.actions";
import { AuthPermissionList } from "./models/auth-permissions.model";



export interface AuthState {
	user: AuthUser | null;
	site: AuthSite | null;
	permissions: AuthPermissionList | null;
};

export const initialAuthState: AuthState = {
	user: null,
	site: null,
	permissions: null,
};

export const authReducer = createReducer(
	initialAuthState,
	on(AuthActions.login, (state, action) => {
		return {
			...state,
		};
	}),
	on(AuthActions.loginSuccess, (state, action) => {
		return {
			...state,
			user: action.user,
			site: action.site,
			permissions: action.permissions
		};
	}),
	// on(AuthActions.logout, (state, action) => {
	// 	return {
	// 		...state,
	// 	};
	// }),
	on(AuthActions.logoutSuccess, (state, action) => {
		return {
			...state,
			user: null,
			site: null,
			permissions: null
		};
	}),
	on(AuthActions.loadCurrentUserSuccess, (state, action) => {
		return {
			...state,
			user: action.user,
			site: action.site,
			permissions: action.permissions
		};
	}),
	on(AuthActions.loadCurrentUserFailure, (state, action) => {
		return {
			...state,
			user: null,
			site: null,
			permissions: null
		};
	})
);