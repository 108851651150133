<div class="dialog-container">
	<h2 mat-dialog-title>Switch site</h2>	
	<mat-dialog-content>
		<div class="picker-toolbar">
			<mat-form-field appearance="outline" class="picker-search-field" subscriptSizing="dynamic">
				<mat-icon matPrefix>search</mat-icon>
				<mat-label>Search</mat-label>
				<input matInput [formControl]="searchControl" (input)="updateClearButtonVisibility()" placeholder="Search">
				<button *ngIf="showClearSearchButton" matSuffix mat-icon-button aria-label="Clear" (click)="clearInput()">
					<mat-icon>close</mat-icon>
				</button>				
			</mat-form-field>
		</div>		
		<div class="picker-data">
			<ng-container *ngIf="dataSource.data.length > 0; else noSitesFound">
				<table mat-table [dataSource]="dataSource" class="site-picker-table">
					<ng-container matColumnDef="isDefault">
						<th mat-header-cell *matHeaderCellDef class="is-default-column"></th>
						<td mat-cell *matCellDef="let element" class="is-default-column">
							<mat-icon *ngIf="element.isCurrent === true">check</mat-icon>
						</td>
					</ng-container>
					<ng-container matColumnDef="siteName">
						<th mat-header-cell *matHeaderCellDef>Site</th>
						<td mat-cell *matCellDef="let element">
							{{ element.siteName }}
						</td>
					</ng-container>
					<ng-container matColumnDef="locatorName">
						<th mat-header-cell *matHeaderCellDef>Locator</th>
						<td mat-cell *matCellDef="let element">
							{{ element.locatorName }}
						</td>
					</ng-container>					
					<!-- Header Row -->
					<tr mat-header-row *matHeaderRowDef="['isDefault', 'siteName', 'locatorName']"></tr>
					<!-- Data Rows -->
					<tr mat-row *matRowDef="let row; columns: ['isDefault', 'siteName', 'locatorName'];" (click)="onSelect(row)"></tr>
				</table>
				<mat-paginator *ngIf="((totalItemCount$ | async) ?? 0)> initialPageSize" [length]="(totalItemCount$ | async) ?? 0"
				[pageSize]="initialPageSize"
				[pageSizeOptions]="initialPageSizeOptions"
				(page)="onPageChange($event)"
				aria-label="Select page"></mat-paginator>
		</ng-container>
		<ng-template #noSitesFound>
			<div class="no-sites-found">
				<h3>No sites found</h3>
			</div>
		</ng-template>
		</div>
		<div *ngIf="isLoading" class="loading-overlay">
      <mat-progress-spinner mode="indeterminate" [diameter]="36"></mat-progress-spinner>
    </div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button (click)="onCancel()">Cancel</button>		
	</mat-dialog-actions>
</div>