import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Location } from '@angular/common';
import { Subscription } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NavigationService implements OnDestroy {
  private history: string[] = [];
  private eventSub!: Subscription;
  
  constructor(
    private router: Router,
    private location: Location) {   
  }

  public startToSaveHistory(): void {
    if (!this.eventSub) {
      this.eventSub = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.history.push(event.urlAfterRedirects)
        }
      })
    }
  }

  public getHistory(): string[] {
    return this.history;
  }

  public goBack(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back()
    } else {
      this.router.navigateByUrl("/")
    }
  }

  public getPreviousUrl(): string {
    if (this.history.length > 0) {
      return this.history[this.history.length - 2];
    }
    return '';
  }

  ngOnDestroy() {
    if (!!this.eventSub) {
      this.eventSub.unsubscribe();
    }
  }
}
