import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { AuthActions } from 'src/app/shared/auth/auth.actions';
import { selectCurrentSite, selectCurrentUser, selectIsLoggedIn } from 'src/app/shared/auth/auth.selectors';
import { AuthService } from 'src/app/shared/auth/auth.service';

@Component({
  selector: 'app-secure-header',
  templateUrl: './secure-header.component.html',
  styleUrls: ['./secure-header.component.scss']
})
export class SecureHeaderComponent {
  @Output() sidenavToggle = new EventEmitter<void>();
  selectIsLoggedIn$ = this.store.select(selectIsLoggedIn)
  currentUser$ = this.store.select(selectCurrentUser);
  currentSiteName$ = this.store.select(selectCurrentSite).pipe(
    map((site) => {
      const logspaceName = site ? site.logspaceName : "";
      const locatorName = site ? site.locatorName : "";
      return logspaceName && locatorName ? `${logspaceName}:${locatorName}` : logspaceName || locatorName || '';
    }
  ));

  constructor(private store: Store<AppState>,
    private auth: AuthService) { }

  onToggleSidenav() {
    console.log("SecureHeader:onToggleSidenav");
    this.sidenavToggle.emit();
  }  

  logout() {
    this.store.dispatch(AuthActions.logout());
    // this.auth.logout().pipe(take(1)).subscribe({
    //   next: (data: any) => {
    //     console.log("logout:", data);
    //   },
    //   error: (err: any) => {
    //     console.log("logout:", err);
    //   }
    // })
  }

  
}
