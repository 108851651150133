import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.reducer';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectIsLoggedIn = createSelector(
	selectAuthState,
	auth => !!auth.user
);

export const selectIsLoggedOut = createSelector(
	selectIsLoggedIn,
	loggedIn => !loggedIn
)

export const selectCurrentUser = createSelector(
	selectAuthState,
	auth => auth.user
)

export const selectCurrentUserId = createSelector(
	selectCurrentUser,
	user => user ? user.userId : null
)

export const selectCurrentSite = createSelector(
	selectAuthState,
	auth => auth.site
)

export const selectCurrentLogspaceId = createSelector(
	selectCurrentSite,
	site => site ? site.logspaceId : null
)

export const selectCurrentLocatorId = createSelector(
	selectCurrentSite,
	site => site ? site.locatorId : null
)

export const selectUserPermissions = createSelector(
	selectAuthState,
	auth => auth.permissions
)