import { Component, Input } from '@angular/core';

@Component({
  selector: 'bda-logo-svg',
  standalone: true,
  imports: [],
  template: `
<svg width="40" height="40" viewBox="0 0 338 385" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;">
    <g transform="matrix(1,0,0,1,-87.0027,-63.5)">
        <path d="M256.007,64L255.993,64L87.503,107.102L90.637,232.49C90.639,232.506 98.03,298.669 134.523,350.041C179.745,413.7 255.957,447.982 255.993,448C255.996,447.998 332.255,413.7 377.477,350.041C413.97,298.669 421.361,232.506 421.363,232.49L424.497,107.102L256.007,64Z" style="fill:rgb(0,36,102);stroke:rgb(0,36,102);stroke-width:1px;"/>
        <path d="M215.373,375.218C189.275,300.8 167.124,194.366 164.573,114.245C201.74,105.106 234.821,103.718 256.662,106.171C267.833,107.426 276.871,108.842 295.359,112.646C379.065,131.54 373.76,182.205 316.895,212.638C312.356,215.068 307.488,217.368 302.311,219.513C312.099,222.233 332.537,230.318 337.758,250.156C344.726,276.634 322.852,320.228 285.945,349.395C274.135,358.728 245.237,380.636 215.373,375.218ZM231.819,258.184C267.003,249.543 298.169,253.088 290.907,275.579C288.384,283.392 259.572,318.878 246.75,325.006L231.819,258.184ZM213.768,147.009L224.869,206.631C234.706,208.057 250.267,203.085 264.926,197.455C290.154,187.766 313.334,168.075 299.646,155.465C290.959,147.463 250.942,144.888 213.768,147.009Z" style="fill:white;stroke:white;stroke-width:1px;"/>
    </g>
</svg>
  `,
  styles: ``
})
export class BidaSvgComponent {
  @Input() class= '';

}
