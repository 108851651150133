import { Component } from '@angular/core';

@Component({
  selector: 'app-secure-footer',
  templateUrl: './secure-footer.component.html',
  styleUrls: ['./secure-footer.component.scss']
})
export class SecureFooterComponent {

}
