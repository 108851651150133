import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-secure-sidenav',
  templateUrl: './secure-sidenav.component.html',
  styleUrls: ['./secure-sidenav.component.scss']
})
export class SecureSidenavComponent {

}
