import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { selectIsLoggedIn } from "./auth.selectors";
import { AppState } from "../../app.reducer";

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
		constructor(
			private router: Router,
			private store: Store<AppState>) {}

		canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

			return this.store.pipe(
				select(selectIsLoggedIn),
				tap(loggedIn => {
					if (!loggedIn) {
						this.router.navigateByUrl('/login');
					}
				})
			);
		}
}