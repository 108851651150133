import { createAction, props } from "@ngrx/store";

export const setAccessToken = createAction(
		"[API] Set Access Token",
		props<{ accessToken: string, tokenExp: string, refreshToken: string }>()
);

export const clearAccessToken = createAction(
		"[API] Clear Access Token"
);

export const ApiActions = {
	setAccessToken,
	clearAccessToken
};