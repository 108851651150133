<mat-toolbar>
	<button mat-icon-button (click)="onToggleSidenav()"><mat-icon>menu</mat-icon></button>
	<span><a routerLink="/"><bda-logo-svg/></a></span>
	<button>{{ currentSiteName$ | async }}</button>
	<span class="bda-toolbar-spacer"></span>
	<!-- <button mat-icon-button><mat-icon>notifications</mat-icon></button> -->
	<ng-container *ngIf="!(selectIsLoggedIn$ | async) else loggedIn">
		<a mat-button class="bda-toolbar-link" routerLink="/login">Login</a>
		<a mat-button class="bda-toolbar-link" routerLink="/login">Sign Up</a>
	</ng-container>
	<ng-template #loggedIn>
		<button mat-button (click)="logout()">Logout</button>
	</ng-template>
	<ng-container *ngIf="currentUser$ | async as currentUser">
		<span>{{ currentUser.firstName }}</span>
	</ng-container>	
</mat-toolbar>
