import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ApiSite, ApiSiteLocatorListResponse, ApiSiteLocatorListResponseItem, SitePickerApiService } from './sitepicker-api.service';
import { BehaviorSubject, catchError, debounceTime, delay, map, Observable, of, Subscription, switchMap, take, tap } from 'rxjs';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { ApiBaseListResponse } from '../shared/api/api.types';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ApiFilterTerm } from '../shared/api/api.list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { selectCurrentUserId } from '../shared/auth/auth.selectors';

export interface SitePickerDataItem {
  siteId: string,
  siteName: string,
  locatorId: string,
  locatorName: string,
  isCurrent: boolean
}

@Component({
  selector: 'app-sitepicker-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule,MatIconModule, MatLabel, MatInputModule, MatFormFieldModule, MatButtonModule, MatTableModule, MatPaginatorModule, ReactiveFormsModule,  MatProgressSpinnerModule],
  templateUrl: './sitepicker-dialog.component.html',
  styleUrl: './sitepicker-dialog.component.scss',
  providers: [SitePickerApiService]
})
export class SitepickerDialogComponent implements OnInit {
  searchControl = new FormControl<string>('');
  showClearSearchButton = false;
  isLoading: boolean = false;

  
  displayedColumns: string[] = ["isDefault", "siteName", "locatorName"];
  columnHeaders: {[key: string]: string} = { siteId: 'SiteID', siteName: 'Site', locatorId: 'LocatorID', locatorName:'Locator', isCurrent: ''}
  // paginator
  initialPageIndex0: number = 0;
  initialPageSize: number = 10;
  initialPageSizeOptions = [10];
  private totalItemCountSubject = new BehaviorSubject<number>(0);
  totalItemCount$ = this.totalItemCountSubject.asObservable();
  private pageIndex0Subject = new BehaviorSubject<number>(0);
  pageIndex0$ = this.pageIndex0Subject.asObservable();
  private pageSizeSubject = new BehaviorSubject<number>(0);
  pageSize$ = this.pageSizeSubject.asObservable();

  private paginatorSub!: Subscription;

  public dataSource = new MatTableDataSource<SitePickerDataItem>();

  dataSites: SitePickerDataItem[] = [];
  siteId: string = '';

  constructor(
    public dlgRef: MatDialogRef<SitepickerDialogComponent>,
    private store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: SitePickerApiService // Inject the service
  ) {
    this.siteId = data.currentSiteId;
  }

  ngOnInit() {
    this.searchControl.valueChanges.pipe(
      debounceTime(300)
    ).subscribe((searchTerms): void => {
      this.loadSites(this.initialPageIndex0, this.initialPageSize);
    });
    this.dataSource = new MatTableDataSource<SitePickerDataItem>(this.dataSites);
    this.loadSites(this.initialPageIndex0, this.initialPageSize);
  }

  ngOnDestroy(): void {
    if (this.paginatorSub) {
      this.paginatorSub.unsubscribe();
    }
  }

  loadSites(pageIndex0: number, pageSize: number) {
    this.isLoading = true;
    // create filterTerms    
    var filterTerms: ApiFilterTerm[] = [];
    const searchTerms: string | null = this.searchControl.value;
    if (!!searchTerms) {
      const terms = searchTerms.split(/\s+/).filter(term => term.length > 0);
      terms.forEach((term) => {
        filterTerms.push({ field: 'name', value: term});
      });       
    }
    this.store.select(selectCurrentUserId).pipe(
      take(1),
      switchMap((userId) => {
        if (!!userId) {
          return this.api.getSiteLocatorList(
            userId,
            [],
            pageIndex0 + 1,
            pageSize,
            1).pipe(
              map((resp: ApiSiteLocatorListResponse) => {
                let siteList: SitePickerDataItem[] = [];
                resp.data.logspaceList.forEach((sli: ApiSiteLocatorListResponseItem) => {
                  siteList.push({
                    siteId: sli.siteId,
                    siteName: sli.siteName,
                    locatorId: sli.locatorId,
                    locatorName: sli.locatorName,
                    isCurrent: (this.siteId === sli.siteId)
                  });
                });
                // Sort siteList first by isDefault, then by name
                siteList.sort((a, b) => {
                  if (a.isCurrent === b.isCurrent) {
                    return a.siteName.localeCompare(b.siteName);
                  }
                  return a.isCurrent ? -1 : 1;
                });
                return {siteList: siteList, currPageNum: Number(resp.paging.currPageNum), totalItemCount: Number(resp.paging.itemTotalCount)};                                              
              }),
              catchError((err: any) => {
                return of({siteList: [], currPageNum: 0, totalItemCount: 0});
              })
            )
        } else {
          return of({siteList: [], currPageNum: 0, totalItemCount: 0});
        }
      })
    ).subscribe({
      next: ({siteList, currPageNum, totalItemCount}) => {
        this.dataSource.data = siteList;
        this.totalItemCountSubject.next(totalItemCount);
        this.pageIndex0Subject.next(currPageNum-1);
        this.pageSizeSubject.next(pageSize);
        this.isLoading = false;
      },
      error: (err: any) => {
        this.isLoading = false;
      }      
    });
  }

  onCancel() {
    this.dlgRef.close();
  }

  onSelect(site: SitePickerDataItem | null) {
    // this.selectedSite = site;
    if (!this.isLoading) {
      if (!!site) {
        this.dlgRef.close({siteId: site.siteId, siteName: site.siteName, locatorId: site.locatorId, locatorName: site.locatorName});
      } else {
        this.dlgRef.close(null);
      }
    }
  }

  updateClearButtonVisibility() {
    this.showClearSearchButton = this.searchControl.value ? true : false;
  }

  clearInput() {
    this.searchControl.setValue('');
    this.showClearSearchButton = false;
  }

  onPageChange(event: PageEvent) {
    this.loadSites(event.pageIndex, event.pageSize);
  }

}
